<template>

    <div id="app" class="white pt-1">


        <template>
  <div class="text-center">
    <v-dialog
      v-model="dialog_modal"
      width="500"
    >

      <v-card>
        <!-- <v-card-title class="text-h6 grey lighten-3 justify-center"> -->
        <v-card-title class="text-h6 grey lighten-3">
          {{dialog_modal_titulo}} 
        </v-card-title>

        <v-card-text class="mt-2 ">
            <v-container>
                <v-row >
                    <v-col
                        class=""
                        v-for="(item, i) in dialog_modal_item"
                        :key="i"
                        :cols="(isMobile) ? 12 : item.cols"  
                    >

                        <!-- COM V-MASK -->
                        <v-text-field 
                            dense
                            v-model="item_edita[item.value]"
                            :label="item.rule == 'S' ? item.text+' *': item.text"
                            :type="item.type"
                            v-if="(item.tag == 'v-text') && (item.vmask)"
                            :rules="item.rule == 'S' ? notnullRules: noRules"
                            :autofocus="i==0"
                            :hint="item.hint"
                            v-mask = "getVmask(item.vmask)"
                        ></v-text-field>
                        
                        <!-- SEM V-MASK  acaba dando erro, por isso separei-->
                        <v-text-field
                            dense
                            v-model="item_edita[item.value]"
                            :label="item.rule == 'S' ? item.text+' *': item.text"
                            :type="item.type"
                            v-if="(item.tag == 'v-text') && (!item.vmask)"
                            :rules="item.rule == 'S' ? notnullRules: noRules"
                            :autofocus="i==0"
                            :hint="item.hint"
                        ></v-text-field>

                    </v-col>
                </v-row>
            </v-container>

        </v-card-text>

        <v-card-actions>

          

          <v-btn
            color="red"
            text
            small
            @click="dialog_modal = false"
          >
            FECHAR
          </v-btn>

          <v-spacer></v-spacer>

          <v-btn
            color="primary"
            small
            @click="dialog_modal_click(dialog_modal_ok)"
            v-if="dialog_modal_ok"
          >
            {{ dialog_modal_ok }}
          </v-btn>

        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

       <!-- <center>
        <h4> LEMBRETES </h4>
        <h4> {{ items_count }} </h4>
       </center>  -->

       <div class="primary pa-2 mt-2" v-if="aberto"
        style="border-radius: 8px;"
       >
    

    <v-dialog
     v-model="aberto"
     :width="(isMobile) ? '380' : '920'"
     :persistent="true"
     @click:outside="abrir()"
    >
    
<!-- DSI -->
<!-- {{ pro_obj_1 }} -->
<!-- {{ dialog_modal_item }} -->
<!-- {{ item_edita }} -->
<!-- {{ item_clicou }} -->
<!-- {{ souDSi }} -->



    <v-dialog
          v-model="dialog_tabela"
          persistent
          max-width="990"
          scrollable
      >

      <v-card class=""
      >
      <!-- height="80%" -->
      <v-card-title>
          <span class="text-h5" 
            v-text="(item_edita.CODIGO>0) ? 'Alterar '+ card_titulo +' '+item_edita.CODIGO : 'Novo '+ card_titulo "
          >
          Alterar
          </span>
      </v-card-title>



          <!-- passo 4 monto os campos editaveis -->
          <!-- :readonly = "readonly" -->
              <v-card-text class="pa-1">
                <v-form
                  ref="form"
                  v-model="valido"
                  >
                  <v-container>
                      <v-row >
                          <v-col
                              class=""
                              v-for="(item, i) in items_editaveis"
                              :key="i"
                              :cols="(isMobile) ? 12 : item.cols"
                              v-if="parseInt(item.cols)>0"   
                          >
                          <!-- :cols="item.cols" -->
                              <v-text-field
                                  dense
                                  v-model="item_edita[item.value]"
                                  :label="item.rule == 'S' ? item.text+' *': item.text"
                                  :type="item.type"
                                  v-if="item.tag == 'v-text'"
                                  @keyup="meu_keyup($event, item.value, item.upper)"
                                  :rules="item.rule == 'S' ? notnullRules: noRules"
                                  v-mask = "getVmask(item.vmask)"
                              ></v-text-field>
                              <!-- :autofocus="i==1" -->


                              <v-combobox
                                  dense
                                  v-model="item_edita[item.value]"
                                  :label="item.text"
                                  v-if="item.tag == 'v-autocomplete'"
                                  :items="item.tag_items"
                                  :rules="item.rule == 'S' ? notnullRules: noRules"
                              ></v-combobox>
                              
                              <v-select
                                  :class="[item.class]"
                                  dense
                                  v-model="item_edita[item.value]"
                                  :label="item.text"
                                  v-if="item.tag == 'v-select'"
                                  :items="item.tag_items"
                                  :rules="item.rule == 'S' ? notnullRules: noRules"

                                  item-value='CODIGO'
                                  item-text='NOME'
                                  :clearable = "item.tag_clear == 'S'"
                                  :persistent-hint="item.cols>2"
                                  :hint="item.hint"
                              >
                              
                              <!-- <template slot="selection" v-slot-scope="item"> -->
                                
                              <!-- <template slot="selection" v-slot-scope="item"> -->
                                <template v-slot:label>
                                  <v-tooltip bottom v-if="item.hint">
                                      <template v-slot:activator="{ on, attrs }">
                                        <span
                                            v-bind="attrs"
                                            v-on="on"
                                        > 
                                          <span :class="item.class">
                                            {{ item.text }} 
                                            <!-- {{ item_edita[item.value] }} -->
                                          </span>
                                        </span>

                                      </template>
                                      <span> {{ item.hint }} </span>
                                    </v-tooltip>
                                </template>
    
                            </v-select>


                              <!-- CAMPO ANOTAÇÕES OU OBSERVAÇÃO -->
                              <v-textarea
                                v-model="item_edita[item.value]"
                                :label="item.text"
                                v-if="item.tag == 'v-textarea'"
                                :hint="item.hint"
                                :persistent-hint="item.cols>2"
                                dense
                                solo
                                :rows="item.rows"
                                :autofocus="i==2"
                                :rules="item.rule == 'S' ? notnullRules: noRules"
                              >
                              </v-textarea>


                              
                              
                          </v-col>
                      </v-row>
                  </v-container>
                </v-form>
              </v-card-text>

      <v-card-actions>
        

          <v-btn
              x-small
              color="red"
              text
              @click="clicou_em('excluir')"
          >
          <!-- @click="remover_tabela(tabela_selecionada, item_edita, card_titulo), dialog_tabela = false" -->
            Excluir
          </v-btn>

          <v-spacer></v-spacer>

          <v-btn
              color="red"
              text
              @click="clicou_em('cancelar')"
          >
            Cancelar
          </v-btn>
          
          &nbsp

          <v-btn
              :disabled="!valido"
              color="primary"
              @click="clicou_em('salvar')"
          >
          <!-- @click="salvar_tabela(tabela_selecionada, item_edita, 'dialog_tabela') -->
            Salvar
          </v-btn>
      </v-card-actions>
      </v-card>
  </v-dialog>


      <v-container
      :class="[cor_fundo, 'pa-4']"
      >    

        <v-row>
            <v-col
            :cols="(isMobile) ? '10' : '11'"
            class="pa-0 pt-2"
            >

                <v-card
                height="40"
                :class="[cor_fundo]"
                elevation="0"
                >

                    <!-- <h4 class="white--text ml-2"> <v-icon color="white">mdi-bell</v-icon> Lembretes</h4> -->
                    <h4 class="white--text ml-2"> <v-icon color="white"> {{ this.icone_tabela_sel }}  </v-icon> {{ card_titulo }}  </h4>


                </v-card>
            </v-col>


                    
            <!-- DSI -->
            <!-- {{ pro_obj_1 }} -->
            <!-- {{pro_obj_menus}} -->
            

            <v-col
            :cols="(isMobile) ? '2' : '1'"
            :class="[isMobile ? 'pa-0 mt-2' : 'pa-0 mt-2']"
            >
                <v-icon
                color="white"
                :class="(isMobile) ? 'ml-7' : 'ml-8' "
                @click="abrir()"
                >
                mdi-close
                </v-icon>
            </v-col>
        </v-row>

        <v-card
            elevation="4"
            class="mt-1 mb-1 pa-1"
            :height="(isMobile) ? '520' : '550' "
        >

            <v-container>
                <v-row>
                    <v-col
                    cols="12"
                    md="2"
                    class=""
                    v-if="(pro_obj_menus)"
                    >
                    <v-card
                        class="mx-auto"
                    >
                        <v-list         
                            dense
                            nav
                        >
                            <v-list-item-group
                            v-model="selectedItem"
                            color="primary"
                            active-class="blue--text"
                            >
        
                                <v-divider
                                    class="mx-0"
                                ></v-divider>
        
                                <!-- passo 2  mostro os items da lista -->
                                <v-list-item
                                    v-for="(item, i) in lista_tabelas"
                                    :key="i"
                                    @click="card_titulo=item.text, tabela(item.click, item)"
                                    class="pa-0 mr-1 "
                                >
                                    <v-list-item-icon class="pa-0 mr-2">
                                    <v-icon v-text="item.icon"></v-icon>
                                    </v-list-item-icon>
        
                                    <v-list-item-content>
                                        <!-- <v-list-item-title v-text="item.text"> -->
                                        <v-list-item-title>
                                        {{ item.text }}

                                        </v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
        
                                <v-divider
                                    class="mx-4"
                                ></v-divider>
        
                            </v-list-item-group>
                        </v-list>
                    </v-card>    


                    </v-col> 
        
        
                    <v-col
                    class="grey lighten-5"
                    v-if="(!pro_modo_edicao)"
                    >
                    <v-card
                        class="mx-auto"
                    >
                    <!-- v-if="items_lista.length > 0" -->

                        <v-card-title
                        class="pa-1 pl-2"
                        v-if="sub_codigo>0"
                        >
                            <!-- <h4 class="primary--text" @click="ultimo_click()" >{{ sub_descricao }} </h4> -->
                            <v-chip
                            color="primary"
                            @click="ultimo_click()"
                            >
                            {{ sub_descricao }}
                            </v-chip>
                            &nbsp
                            <h5 class="mb-2 grey--text">{{ sub_codigo }} </h5>

                            &nbsp

                            <v-chip
                            color="primary"
                            @click="ultimo_click()"
                            >
                            VOLTAR
                            </v-chip>

                        </v-card-title>

                        <v-card-title
                            v-if="card_titulo"
                            class="grey lighten-3"
                        >
  
    
                            <v-row dense>
                                <v-col class=""  cols="12" md="8">
                                    <v-row class="">
                                        <v-col>
                                            <span class="text-h6 black--text" style="font-size:16px !important"> {{ card_titulo }} </span>
                                            <!-- <span > {{ card_titulo }} </span> -->
                                            <span class="red--text mb-2 ml-1"> {{ items_lista.length }} </span>

                                        </v-col>


                                        <v-col cols="4" md="2">
                                            <v-checkbox
                                                    v-model="antigos"
                                                    label="Tudo"
                                                    dense
                                                    style="margin-left: -4px"
                                                    class="pa-0"
                                                    hide-details="true"
                                                    @click="tabela('ALERTA','')"
                                                >
                                                <template v-slot:label>
                                                <span class="blue--text ml-n2" style="font-size:12px !important">Todos</span>
                                                </template>
                                            </v-checkbox>
                                        </v-col>
                                        
                                    </v-row>

                                </v-col>


                                <v-col class=""  cols="12" md="4">
                                    <v-row class="">
                                        <v-col class="" cols="8" md="9" >
                                            <v-text-field
                                                dense
                                                v-model="search"
                                                clearable
                                                append-icon="mdi-magnify"
                                                label="Procurar"
                                                single-line                
                                                hide-details
                                                @keyup.esc="search=''"  

                                                >
                                            </v-text-field>
                                            
                                        </v-col>

                                        <!-- <v-spacer></v-spacer> -->

                                        <v-col cols="3"  md="3">
                                            <v-btn
                                                color="primary"
                                                small
                                                @click="nova_tabela('item_edita','dialog_tabela')"
                                            >
                                                Novo
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
        
                        
                        </v-card-title>
        
                        <v-card-subtitle>
        
                        </v-card-subtitle>
        
                        <v-card-text class="pa-1"
                            v-if="card_titulo"
                        >
                        <div>
                            <v-row>
                                <v-col>
                                    <v-card>
                                    <!-- passo 5 monto a lista -->
                                        <v-data-table
                                        v-model="selected"
                                        single-select
                                        item-key="CODIGO"
                                        @click:row="seleciona_linha"

                                        :headers="items_header"
                                        :items="items_lista"
                                        :height="(isMobile) ? '370' : (items_lista.length>0) ? '440' : ''"

                                        class="elevation-1"
                                        :items-per-page="500"
                                        disable-sort
                                        
                                        hide-default-footer
                                        :search="search"
                                        :mobile-breakpoint="55"
                                        dense
                                        >

                                            <template slot="no-data">
                                            <div></div>
                                            </template>


                                            <!-- <template v-slot:item.DATA_ALERTA="{ item }">
                                                 <strong class="blue--text" v-if="item.D_2=='S'"> {{ item.D_2 }} </strong>
                                            </template> -->

                                            <template v-slot:item.VISTO="{ item, index }">
                                                <div @click="muda_visto(item, true, 'VISTO')">
                                                    <v-icon small color="green" v-if="(item.VISTO=='S')">
                                                    mdi-check-bold 
                                                    </v-icon>
                                                    <v-icon small  v-else>
                                                    mdi-radiobox-blank 
                                                    <!-- mdi-infinity  -->
                                                    </v-icon>
                                                </div>
                                            </template>

                                            <template v-slot:item.ENVIAR_ZAP="{ item, index }">
                                                <div @click="dialog_modal_abrir('Programar Lembrete', item)">
                                                    <v-icon small color="green" v-if="(item.ENVIAR_ZAP=='S')">
                                                    mdi-check-bold 
                                                    </v-icon>
                                                    <v-icon small  v-else>
                                                    mdi-radiobox-blank 
                                                    <!-- mdi-infinity  -->
                                                    </v-icon>
                                                </div>
                                            </template>



                                            <template v-slot:item.TIPO="{ item }">
                                                <!-- <span> {{ item.TIPO }} </span> -->
                               
                                                <v-icon size="18" class="pr-1" color="blue"> 
                                                    
                                                   {{ (item.TIPO == 'TAREFA') ? 'mdi-alarm-check' : 'mdi-bell' }} 
                                                </v-icon> 
                                            </template>

                                            <template v-slot:item.DATA_ALERTA="{ item }">
                                                <span> 
                                                    <span class="fs-10">
                                                        {{ (item.TIPO == 'TAREFA') ? 'Desde' : 'Até'  }} 
                                                    </span> 
                                                    <strong :class="[(dif_datas(today, item.DATA_ALERTA)>=0) ? 'red--text' : '']">
                                                        {{ (item.x_DATA_ALERTA) }}                                                    
                                                    </strong> 
                                                </span>
                                            </template>
                                            
        
                                            <template v-slot:item.actions="{ item }">
                                                <v-icon
                                                    class="mr-2"
                                                    @click="editar_tabela(item, 'item_edita', 'dialog_tabela')"
                                                    color="blue"
                                                    :size = "size_mobile"
                                                >
                                                mdi-pencil
                                                </v-icon>
                                            </template>
        


                                            <!-- //passo 6 personalizo as acoes dos botoes na lista  INICIO-->
                                            <template v-slot:item.actions_clock="{ item }">
                                                <v-tooltip 
                                                    bottom
                                                >
                                                    <template v-slot:activator="{ on, attrs }">
                                                    <v-icon
                                                        class="mr-2"
                                                        :size = "size_mobile"
                                                        v-bind="attrs"
                                                        v-on="on"
                                                        color="blue"
                                                        @click="tabela('ANTIGO_HORARIO','SUB',item), card_titulo='Horários'"
                                                    > 
                                                    <!-- :color="item.CONTRATO ? 'blue': 'grey'" -->
                                                    mdi-clock-time-four-outline 
                                                    </v-icon>
        
                                                    </template>
                                                    <span> Horários</span>
                                                </v-tooltip>
                                            </template>
        

                                            
                                            <template v-slot:item.actions_proced="{ item }">
                                                <v-tooltip 
                                                    bottom
                                                >
                                                    <template v-slot:activator="{ on, attrs }">
                                                    <v-icon
                                                        class="mr-2"
                                                        :size = "size_mobile"
                                                        v-bind="attrs"
                                                        v-on="on"
                                                        color="blue"
                                                        @click="tabela('VALORES_AMB','SUB',item), card_titulo='Procedimentos'"
                                                    > 
                                                    mdi-format-list-checkbox 
                                                    </v-icon>
        
                                                    </template>
                                                    <span> Procedimentos</span>
                                                </v-tooltip>
                                            </template>


                                            <!-- //passo 6 personalizo as acoes dos botoes na lista  FIM -->
        
                                        </v-data-table>
                                    </v-card>
        
                                </v-col>
                            </v-row>
        
                        </div>
                        </v-card-text>
                    </v-card>
                </v-col>     
            </v-row> 
            <span class="fs-minimo">Lembretes</span>
            </v-container>  

        </v-card>
    
      </v-container>
    </v-dialog>
        <!-- </v-dialog> -->
      </div> 
    </div>
    </template>
    
    <script>
    
    import DSibasico from '../assets/js/DSi_basico'//chamo funcao do arquivo
    import DSisql from '../assets/js/DSi_sql'//chamo funcao do arquivo
    import { logado } from '../assets/js/DSi_rotinas'
    import { send_whats_api_45 } from '../assets/js/DSi_whats'

    
    
    export default {
    
    
      props:{
        aberto: Boolean,
        salvou: Boolean,
        pro_tabela: String,
        pro_titulo: String,
        show_menu  : Boolean,

        pro_obj_1: Object,
        pro_obj_menus: Object,
        pro_modo_edicao: Boolean,
      },
    
      data: () => ({    
    
        souDSi: false,

        dialog_modal : false,
        dialog_modal_titulo : '',
        dialog_modal_ok : '',
        dialog_modal_item:[],
        item_edita: {},

        item_clicou: {},


        // aberto: true,
        cor_fundo: 'primary lighten-0',
    
        isMobile: false,

        antigos: false,

        colunas: 12,

        user:[],

        // ------------ TABELAS INICIO ---------------
        icone_tabela_sel:'',
        selected: [],

        selectedItem: -1,
        card_titulo:'',
        dialog_tabela: false,
        tabela_selecionada:'',
        search: '',

        lista_tabelas:[],
        selected: [],
        items_lista:[],
        items_header: [],
        items_editaveis: [],
        items_count:[],
        item_edita: {},

        sub_codigo:0,
        sub_descricao:'',

        valido: false,
        
        size_mobile:22,

        w_data: '99px',

        notnullRules: [
          v => !!v || 'necessário'
        ],
        noRules:[v => true],


        // ------------ TABELAS FIM ---------------
    
        snackbar2: false,
        snack_text2: 'Nova Mensagem',
        snack_color2: "success",
    

        count_alerta: 0,

    
        hoje :'',
        hoje_week:'',
        hora :'',
        data_hora :'',
        today :'',
        quem_alterou:'',
    
      }),
    
    
      created(){
        this.onCreated()
      },

    
      watch:{
   
        count_alerta(a){
          this.$emit('muda_count_lembretes', a)
        },

        aberto(a){
            // alert(this.pro_tabela)
            if (a){
                this.ultimo_click()
            }
            
        }
        
      },
    

    
      methods:{

        dialog_modal_abrir(acao, item){

            this.item_clicou = Object.assign({}, item)


            if (acao = 'Programar Lembrete'){

                this.dialog_modal           = true,
                this.dialog_modal_titulo    = 'Lembrete via WhatsApp',
                this.dialog_modal_ok        = acao,
                // this.dialog_modal_item      = null,
                this.dialog_modal_item      = [
                    { text: 'Enviar em', value: 'DATA', tag:'v-text', type:'date', cols:'4', width: '130px',rule:'S' },
                    { text: 'às', value: 'HORA', tag:'v-text', type:'time', cols:'3', width: '130px',rule:'S' },
                    { text: 'Para o Número', value: 'NUMERO',tag:'v-text', type:'text', cols:'5',rule:'S',vmask:'cel'},
                ]

                this.item_edita = {"DATA": item.DATA_ALERTA, "HORA": "10:00", "NUMERO": "(44) 99968-5172" }

                this.muda_visto(this.item_clicou, true, 'ENVIAR_ZAP')

            } 
        },

        dialog_modal_click(acao){

            if (acao = 'Programar Lembrete'){
                this.envia_lembrete_45()
            }

            this.dialog_modal = false //fecha dialogo
        },

        async envia_lembrete_45(){
            // console.log('envia_lembrete:');
            await send_whats_api_45('LEMBRETE', 'LEMBRETE.VUE', 'DSI' , this.item_edita.NUMERO, this.item_clicou.DESCRICAO, this.item_edita.DATA, this.item_edita.HORA );//envia zap

        },

        muda_visto(item, perguntar, tag){
            // console.log(item);


            // if (this.obj_user.SUPER!='S'){
            //  alert('Somente Super pode alterar o Visto')
            //  return 'exit'
            // }

            if (perguntar){
                if (!confirm(`Alterar ${tag}?`)){
                    return 'exit'
                }
            }



            let visto = item[tag]

            if (visto != 'S'){ visto = 'S'} else {visto = 'N'}
            let codigo = item.CODIGO


            let sql = `update alerta set ${tag} = '${visto}' where CODIGO = ${codigo}`
            this.crud_sql(sql)

            // ATUALIZA LISTA SEM DAR CLOSE OPEN NA TABELA
            // let index = this.items_conta.indexOf(item)
            let index = this.items_lista.findIndex( x => x.CODIGO === item.CODIGO );
            this.$set(item, tag,  visto)
            Object.assign(this.items_lista[index], item)

            this.count_novas()

        },

        async onCreated(){

          if (await logado(this)){

             this.isMobile = window.innerWidth < 600
             this.souDSi    = (sessionStorage['souDSi'] == 'S') ? true : false 

             this.refresh_data_hora()
             this.permite_items()

             await this.ler_sessions()
             await this.count_novas()
          }

        },


        async clicou_em(acao){



            if (this.pro_modo_edicao){
                await this.abrir()
            }


            if (acao == 'cancelar'){
                this.dialog_tabela = false
            }
            else if (acao == 'salvar'){  
               await this.salvar_tabela(this.tabela_selecionada, this.item_edita, 'dialog_tabela')
               await this.muda_salvou(true, this.tabela_selecionada)
            }
            else if (acao == 'excluir'){

                await this.remover_tabela(this.tabela_selecionada, this.item_edita,  this.card_titulo) 
                await this.muda_salvou(true, this.tabela_selecionada)
                this.dialog_tabela = false
            } 


        },

        async ler_sessions(){
            await this.$set(this.user, 'CODIGO', sessionStorage['lg:CODIGO'])
        },
        

        permite_items(){
        //passo 1 monto os items e suas tabelas e permissoes

            let s = []

            this.lista_tabelas = [
            { text: 'Lembretes', count: 0 , icon: 'mdi-bell', click:'ALERTA' },
            ]

        },

        async tabela(tabela, item){
        //passo 3 executo a acao quando clicar nos items e monto os campos editaveis e os campos do data-table


            // console.log(item);
            // this.icone_tabela_sel = item.icon
            
            if (!item){
             sessionStorage['tabela_clicou']   = tabela
             sessionStorage['tabela_titulo']   = this.card_titulo
            }

            this.search = ''//limpa filtro

            this.tabela_selecionada = tabela


            //limpa array
            this.items_lista       = [] //recebe os dados do sql
            this.items_header      = [] //campos que tem que mostrar no header
            this.items_editaveis   = [] //campos editaveis mas nao tem que mostrar no header

            if (tabela=='WHATS'){

            }

            else if (tabela=='ALERTA'){

                this.count_novas()//atualiza o contador

                this.icone_tabela_sel = 'mdi-bell'

                this.col_menu = 1

                //limpa campos sub
                this.sub_codigo    = 0
                this.sub_descricao = ''

                this.items_header = [

                    { text: '..', value: 'actions', sortable: false, type:'hide', cols:'0',width:'5px'},
                    // { text: 'CODIGO', value: 'CODIGO', type:'number', cols:'0' },
                    { text: 'DATA', value: 'DATA_ALERTA', tag:'v-text', type:'date', cols:'2', width: '130px',rule:'S' },
                    { text: 'VISTO', value: 'VISTO', tag:'v-text', type:'text', cols:'2', width:'20px',rule:'N' },

                    // { text: 'TIPO', value: 'TIPO',
                    //   type:'text',
                    //   tag:'v-select',
                    //   tag_items:[
                    //       'LEMBRETE',         
                    //       'TAREFA',
                    //   ],
                    //   cols:'2',rule:'S',width:'20px' 
                    // },

                    { text: 'WHATS', value: 'ENVIAR_ZAP', type:'text', tag:'v-select', tag_items:['S','N'],cols:'2',rule:'S'  }, 
                    // { text: 'CODIGO_OPERADOR', value: 'CODIGO_OPERADOR', tag:'v-text', type:'date', cols:'2', width: this.w_data,rule:'S' },
                    { text: 'DESCRIÇÃO', value: 'DESCRICAO',tag:'v-textarea', type:'text', cols:'12', rows:'8',rule:'S', width:(this.isMobile) ? '300' : '500px' },
                    
                ]

                // clono os items
                this.items_editaveis = this.items_header.map((x) => x); //clonando array copiar array

                // removo os campos que nao quero visivel somente para edicao
                this.items_editaveis.splice(this.items_editaveis.findIndex(x => x.value === "VISTO") , 1);//removo a coluna senha
                this.items_editaveis.splice(this.items_editaveis.findIndex(x => x.value === "ENVIAR_ZAP") , 1);//removo a coluna senha

                if (!this.souDSi){
                   // removo os campos que nao quero visivel somente para edicao
                   this.items_header.splice(this.items_header.findIndex(x => x.value === "ENVIAR_ZAP") , 1);//removo a coluna senha
                }

                // let sete_dias = this.incDay(this.today,7,'us')
                let sete_meses = this.incMonth(this.today,7,'us')

                // let where = `where alerta.data_alerta between '${this.today}' and '${sete_dias}' and codigo_operador = ${this.user.CODIGO}`
                // let where = `WHERE (data_alerta BETWEEN '${this.today}' and '${sete_meses}'
                //               AND codigo_operador = ${this.user.CODIGO})
                //               OR (tipo = 'TAREFA' AND visto <> 'S')`
                let where = `WHERE (data_alerta BETWEEN DATE_SUB(CURDATE(), INTERVAL 6 MONTH) AND '${sete_meses}') and (codigo_operador = ${this.user.CODIGO}) AND (VISTO IS NULL OR VISTO <> 'S')`
                if (this.antigos){
                    where = `where codigo_operador = ${this.user.CODIGO}`
                }
                let sql = `select * from alerta ${where} order by data_alerta desc`


                //  console.log( tabela+' : '+sql);
                this.crud_abrir_tabela(sql,'items_lista')

            }

        },

        async before_update(tabela){
        // passo 8 antes de salvar verifico alguns campos necessarios em determinada tabela


            // VERIFICA CAMPOS NUMERICOS QUE NAO PODEM IR VAZIOS

            let VERIFICAR = [
                // 'USUARIOS|CODIGODOUTOR',
            ]
            
            let CAMPOS = []
            for (let i = 0; i < VERIFICAR.length; i++) {
                
                CAMPOS = VERIFICAR[i].split("|");//divide
                if (tabela==CAMPOS[0]){//verifica se é a tabela selecionada
                    if (!this.item_edita[CAMPOS[1]]){//verifica se vazio
                        if (!this.item_edita[CAMPOS[0]]){this.$set(this.item_edita, CAMPOS[1], 0) }
                    }
                }
            }
        },

        async ultimo_click(){

          let tabela = sessionStorage['tabela_clicou']

          if (this.pro_tabela){
            this.card_titulo = this.pro_titulo
            await this.tabela(this.pro_tabela,'')

            sessionStorage['tabela_clicou'] = this.pro_tabela
          }
          else if (tabela){
              this.card_titulo = sessionStorage['tabela_titulo'] 
              await this.tabela(tabela,'')
          }


          //   ja abre em modo edicao
          if (this.pro_modo_edicao){

              if (this.pro_obj_1){
                // alert('editar')
                this.editar_tabela(this.pro_obj_1, 'item_edita', 'dialog_tabela')
              }
              else{
                // alert('novo')
                this.nova_tabela('item_edita','dialog_tabela')
              }
         }

        },

        meu_keyup(event,campo,upper){
  
          //identifica se o campo é double e muda o display
          if ((campo.includes('double_') || campo.includes('VALOR')) ){
              this.item_edita[campo] =  this.moeda(event)
              // @keyup="editedItem.double_credito = moeda($event)"
          }
          else if (campo.includes('CEP')) {
  
              this.consulta_cep(this.item_edita[campo])    
          }
          else{
              if ((campo.includes('EMAIL')==false) &&
              (campo.includes('SENHA')==false) &&
              (campo.includes('SENHA_WEB')==false)){
  
                  if (upper !=='N'){
                  this.allUpper(event, campo)
                  }
                  
              }
          }
        },

        allUpper(event, field){
              let a = event.target.value;//captura o valor do event
              if (a!==''){  
                  a = a.toUpperCase();
                  this.item_edita[field] = a;
              } 
        },

        firstUpper(event, field){
              // console.log(event);
              let a = event.target.value;//captura o valor do event
              if (a!=='' && a.length==1){  
                a = a.toUpperCase();
                this.item_edita[field] = a;
              } 
        },


        getVmask(qual){
            
            if (qual == 'cel'){
                return "(##) #####-####"
            }
            else if (qual == 'fone'){
                return "(##) ####-####"
            }
            else if (qual == 'cnpj'){
                return "##.###.###/####-##"
            }
            else if (qual == 'cpf'){
                return "###.###.###-##"
            }
            else if (qual == 'cep'){
                return "#####-###"
            }
            else{
                return ""
            }
          },


          async count_novas(){
                
                let sql = `select count(codigo) as COUNT 
                            from alerta 
                           where (alerta.data_alerta <= '${this.today}' and codigo_operador = ${this.user.CODIGO} AND (VISTO IS NULL OR VISTO <> 'S')) OR (tipo = 'TAREFA' AND visto <> 'S')`

                //  console.log('count_novas:', sql);
                await this.crud_abrir_tabela(sql, 'items_count' )

                // console.log('items_count:', this.items_count[0].COUNT);
                this.count_alerta = await this.items_count[0].COUNT

            }, 

        refresh_data_hora(){
        
                let a = new Date().toLocaleDateString('pt-Br',{ datestyle: 'short', hour12: false, timeZone: 'America/Sao_Paulo' });//formato dd/mm/yyyy  
                // console.log('hoje = '+ a);
                this.hoje = a;

                a =   this.hoje.slice(3, 5) + '/'+this.hoje.slice(0, 2)+'/'+this.hoje.slice(6, 10) ;//formato mm/dd/yyyy  
                // console.log('hoje_mm_dd_yyyy = '+ a);
                this.hoje_mm_dd_yyyy = a;


                // a = new Date().toLocaleTimeString('pt-Br',{ timeStyle: 'short', hour12: false, timeZone: 'America/Sao_Paulo' });
                a = new Date().toLocaleTimeString('pt-Br',{ hour12: false, timeZone: 'America/Sao_Paulo' });
                // console.log('hora = '+ a);
                this.hora = a;

                a = this.hoje + '_'+ this.hora;
                // console.log('data_hora = '+ a);
                this.data_hora = a;


                a = this.hoje.slice(6, 10) +'-'+ this.hoje.slice(3, 5) +'-'+ this.hoje.slice(0, 2) ; //formato us yyyy-mm-dd
                // console.log('today = '+ a);
                this.today = a;
        },

        define_defaults(tabela){

            this.refresh_data_hora()

            // passo 7 defino valores default para alguns campos de determinada tabela
            if (tabela=='ALERTA'){
             this.$set(this.item_edita, 'CODIGO_OPERADOR', this.user.CODIGO)
             this.$set(this.item_edita, 'VISTO'          , 'N')
             this.$set(this.item_edita, 'TIPO'           , 'LEMBRETE')
            }

        },

        async seleciona_linha(row) {
        // console.log('row:');
        // console.log(row);
             this.selected =  [{"CODIGO" : row.CODIGO}] //via v-modal no data-table
        },

    
        async abrir(){
          // this.aberto=!this.aberto
          this.$emit('muda_aberto_lembretes', !this.aberto)
        },
    
        async muda_salvou(estado, tabela){
          // this.aberto=!this.aberto
          this.$emit('muda_salvou', estado, tabela)
        },
    
    
        manda_emit(acao,a,b,c){
          this.$emit('emit_executa',acao,a,b,c)
        }, 
    
    

    
         getFormatTime(hora){
            if (hora !== undefined){
               let shora =   hora.substring(0,2) + ':'+ hora.substring(3,5)//formato a hh:mm
               return shora 
            }else{
              return ''
            }  
          },
    
          getFormatDate(data){
            let dia =   data.substring(8,10) + '/'+ data.substring(5,7)+ '/'+ data.substring(0,4);//formato a dd/mm/yyyy
            return dia 
          },
    
    
    
        // --------------------------------SQL-------------------------------------
    
        //ABRE OS DADOS DA TABELA
        async crud_abrir_tabela(sql, items_name){
            await DSisql.crud_abrir_tabela(this, sql, items_name)
    
            // console.log(this.items_mensagens);
        }, 
    
        async crud_sql (sql) {
            await DSisql.crud_sql(this, sql)
        },
    
        alerta(text,color){
            DSibasico.alerta(this, text, color)
        },

        dif_datas(a,b){
            return DSibasico.dif_datas(a,b)
        },
    
        incDay(data,dias,pt){
            return DSibasico.incDay(data,dias,pt)
        },
    
        incMonth(data,dias,pt){
            return DSibasico.incMonth(data,dias,pt)
        },
    
        alerta2(text,color){
            // alert(text);
             this.snackbar2 = true;
             this.snack_text2= text;
    
             this.snack_color2 = color;
        },
    
        define_data_hora(){
            DSibasico.define_data_hora(this)//passo o this para que la ele defina as variaveis daqui
        },
    


           //---------------importando funcoes sql ----------------------- INICIO
          //EXECUTAR UM SQL TIPO UPDATE
          async crud_sql (sql) {
              await DSisql.crud_sql(this, sql)
          },
  
          async tabela_create(tabela, obj_edita){
              await DSisql.tabela_create(this, tabela, obj_edita)
          },
  
          async tabela_update(tabela, obj_edita){
              await DSisql.tabela_update(this, tabela, obj_edita)
          },
  
          //ABRE OS DADOS DA TABELA
          async crud_abrir_tabela(sql, items_name){
              await DSisql.crud_abrir_tabela(this, sql, items_name)
          }, 
  
          //FICA NO BOTAO SALVAR.. VERIFICA SE UPDATE OU CREATE
          async salvar_tabela(tabela, obj_edita, dialog_name){

              await this.before_update(this.tabela_selecionada)//trato alguns campos necessarios

              await DSisql.salvar_tabela(this,tabela, obj_edita, dialog_name)
          },
  
          async remover_tabela(tabela, obj_edita, titulo){
              await DSisql.remover_tabela(this, tabela, obj_edita, titulo)
          },
  
          async editar_tabela(item, obj_edita_name, dialog_name) {
              await this.seleciona_linha(item)
              await DSisql.editar_tabela(this, item, obj_edita_name, dialog_name)
          },
  
          nova_tabela(obj_edita_name,dialog_name){

             DSisql.nova_tabela(this, obj_edita_name,dialog_name)

             //define alguns valores default
             this.define_defaults(this.tabela_selecionada)
          },


      },
    
    
    }
    </script>
    
    <style>
    .container{
        /* max-width: 100%; */
      }
    
    .names{
      color: #424949;
    }
    
    
      .scrollable {
        overflow-y: auto;
        height: 90vh;
        background-color: blue;
      }
    
      /* box das mensagens */
      .chat-container{
        box-sizing: border-box;
        /* height: calc(100vh - 9.5rem); */
        /* height: 400px; */
        
        height: 50vh; /* 50% altura tela*/
        /* max-height: 600px; */
        /* width: 100vw; 100% largura tela*/
        overflow-y: auto;
        padding: 10px;
        background-color: #f2f2f2;
        /* background-color: white; */
        /* background-color: red; */
      }
      .message{
        /* margin-bottom: 3px; */
      }
      .message.own{
        text-align: right;
      }
      .message.own .content{
        /* background-color: lightgreen; */
        background-color: lightskyblue;
      }
      .chat-container .username{
        font-size: 14px;
        font-weight: bold;
    
      }
      .chat-container .content{
        padding: 2px 8px;
        background-color: white;
        /* background-color: lightgreen; */
        border-radius: 10px; /* bordas arrendondadas */
        display:inline-block;
        box-shadow: 0 1px 3px 0 rgba(0,0,0,0.2), 0 1px 1px 0 rgba(0,0,0,0.14), 0 2px 1px -1px rgba(0,0,0,0.12);
        max-width: 90%;
        word-wrap: break-word;
        font-size: 12px;
        color: black;
        }
      @media (max-width: 480px) {
        .chat-container .content{
          max-width: 60%;
        }
      }
    
      .hora{
        font-size: 9px;
      }

      .fs-minimo{
      font-size: 9px !important;
      color: #DCDCDC;
    }
      .fs-7{
    font-size: 7px !important;
    }
    .fs-8{
        font-size: 8px !important;
    }
    .fs-9{
        font-size: 9px !important;
    }
    .fs-10{
        font-size: 10px !important;
    }
    .fs-11{
        font-size: 11px !important;
    }
    .fs-12{
        font-size: 12px !important;
    }
    .fs-13{
        font-size: 13px !important;
    }
    .fs-14{
        font-size: 14px !important;
    }
    .fs-15{
        font-size: 15px !important;
    }
    .fs-16{
        font-size: 16px !important;
    }
    .fs-17{
        font-size: 17px !important;
    }
    .bold{
        font-weight: bold !important;
    }
    
    </style>