<template>
  <div>

    <div class="two-columns">
  <p>Este é um texto que será dividido em duas colunas automaticamente.</p>
  <p>___________________________</p>
  <p>Ele continuará fluindo entre as colunas de forma natural.</p>
  <p>___________________________</p>
</div>


    <editor-menu-bar :editor="editor">
      <button @click="addTwoColumnSection">Duas Colunas</button>
    </editor-menu-bar>

    <editor-content :editor="editor" />
  </div>
</template>

<script>
import { Editor, EditorContent, EditorMenuBar } from "@tiptap/vue-2";
import StarterKit from "@tiptap/starter-kit";

export default {
  components: {
    EditorContent,
    EditorMenuBar,
  },
  data() {
    return {
      editor: new Editor({
        extensions: [StarterKit],
        content: "<p>Texto normal...</p>",
      }),
    };
  },
  methods: {
    addTwoColumnSection() {
      const html = this.editor.getHTML();
      this.editor.commands.setContent(
        // html + '<div class="two-columns"><p>Texto final em duas colunas...</p></div>'
        html + `    <div class="two-columns">
  <p>Este é um texto que será dividido em duas colunas automaticamente.</p>
  <p>Ele continuará fluindo entre as colunas de forma natural.</p>
</div>`
      );
    },
  },
  beforeDestroy() {
    this.editor.destroy();
  },
};
</script>

<style>
/* .two-columns {
  column-count: 2;
  column-gap: 20px;
  padding: 10px;
  border: 1px solid #ccc;
  margin-top: 20px;
} */

.two-columns {
  column-count: 2;
  column-gap: 20px; /* Espaço entre as colunas */
  text-align: center;
}

</style>
